import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./LanParty.module.css";
import fifa from "../../../resources/FIFA.png";
import lol from "../../../resources/LeagueofLegends.jpeg";
import cs2 from "../../../resources/CS2.png";
import florin from "../../../resources/Florin.jpg";
import LanPartyJocuri from "../../../components/LanPartyJocuri/LanPartyJocuri";
import LanPartyForm from "../../../components/lanPartyForm/LanPartyForm";
import useStore from "../../../store/userStore";
import CaptainTeam from "../../../components/CaptainTeam/CaptainTeam.jsx";
import Axios from "axios";
import { APIURL } from "../../../constants.js";
import { lanPartyDescription } from "../../../staticData/general/lanPartyDescription.js";
import { Snackbar, Slide, Alert, Button } from "@mui/material";
import CustomModal from "../../../components/modal/CustomModal";
import { fifaRules as fifaRegulament } from "./fifaRules.js"
import { csRules as csRegulament } from "./csRules.js"
import { lolRules as lolRegulament } from "./lolRules.js"


const lanPartyId = 11;

function LanParty() {
  const [eventsAttended] = useStore((state) => [state.eventsAttended]);
  const [addEventAttended] = useStore((state) => [state.addEventAttended]);
  const user = useStore((state) => state.user);
  const [teamId, setTeamId] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [wentBack, setWentBack] = useState(false);
  const [regulament, setRegulament] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const toastObj = useRef({
    type: "error",
    message: "",
  });
  const [games, setGames] = useState([
    {
      id: 6,
      name: "League of Legends",
      img: lol,
      ales: "notYet",
      regulament: lolRegulament
    },
    {
      id: 7,
      name: "CS2",
      img: cs2,
      ales: "notYet",
      regulament: csRegulament
    },
    {
      id: 8,
      name: "Fifa 24",
      img: fifa,
      ales: "notYet",
      regulament: fifaRegulament
    },
  ]);

  const [selectedGame, setSelectedGame] = useState();
  const [formStep, setFormStep] = useState(1);
  // Daca pui in depencies formStep crapa rau

  useEffect(() => {
    // console.log(formStep, "formStep");
    const gamesIDs = [6, 7, 8];
   


    gamesIDs.forEach((gameID) => {
      if (eventsAttended.hasOwnProperty(gameID)) {
        setSelectedGame(games[gameID - 6]); // hardcodat in draci, daca aveti vreo problema mesaj la 0724127365 si ne batem
        const tempGames = games;

       setRegulament(games[gameID - 6].regulament);

        tempGames.forEach((game) => {
          if (game.id !== gameID) game.ales = false;
          else game.ales = true;

          setGames(tempGames);
          setFormStep(3);
        });
        
        if (gameID !== 8) {
          Axios.post(`${APIURL}/hackathon/getTeam`, {
            id: user.id,
            hackathonId: games[gameID - 6].id,
          })
            .then((res) => {
              setTeamId(res.data.teamId);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  }, [eventsAttended, games, user.id]);

  let onNextLanPartyJocuri = (selectedGame) => {
    toastObj.current.type = "info";
    toastObj.current.message = "Atenție! Poți participa doar la o singură competiție!";
    setOpenToast(true);
    setSelectedGame(selectedGame);
    setFormStep(formStep + 1);

  };

  let onInfo = (game) => {
    if (game.ales === true) {
      if (game.id !== 8) {
        setFormStep(4);
      } else if (game.id == 8) {
        openModal();
      }
    } else if (game.ales === false) setOpenToast(true);
  };
  let formular;

  let onInregistrare = (gameId) => {
    setFormStep(2);

    addEventAttended(gameId);
    const tempGames = games;
    games.forEach((game) => {
      if (game.id !== selectedGame.id) game.ales = false;
      else game.ales = true;
    });
    setGames(tempGames);
  };

  const onBackFormular = () => {
    setFormStep(formStep - 1);
  }

  switch (formStep) {
    case 1:
      formular = <LanPartyJocuri games={games} onNext={onNextLanPartyJocuri} />;
      break;
    case 2:
      formular = (
        <div>
          <LanPartyForm onNext={onInregistrare} gameId={selectedGame.id} onBack={onBackFormular} />
        </div>
      );
      break;
    case 3:
      formular = <LanPartyJocuri games={games} onNext={onInfo} />;
      break;
    case 4:
      formular = (
        <div className={styles.containerEchipaLan}>
          <CaptainTeam
            display={true}
            userId={user.id}
            paramEventId={selectedGame.id}
            paramTeamId={teamId}
            teamSize={5}
          />
          <div>
            <button
              onClick={() => {
                setFormStep(3);
              }}
              className={styles.buttonFormLanParty}
            >
              Înapoi
            </button>

            <button
              onClick={() => {
                openModal();
              }}
              className={styles.buttonFormLanParty}
            >
              Reguli
            </button>
          </div>

        </div>
      );
      break;
    default:
      formular = <LanPartyJocuri games={games} onNext={onNextLanPartyJocuri} />;
  }
  function lanPartySignUp() {
    Axios.post(`${APIURL}/attendance/${user.id}`, {
      eventId: lanPartyId,
    })
      .then((res) => {
        toastObj.current.type = "success";
        toastObj.current.message = res.data;
        setOpenToast(true);
        addEventAttended(lanPartyId);
      })
      .catch((error) => {
        toastObj.current.message = "Eroare de server";
        setOpenToast(true);

        console.log(error);
      });
  }

  return (
    <>
      <CustomModal isOpen={isModalOpen} onClose={closeModal} title={"Regulament"}>
        <p>{regulament}</p>
      </CustomModal>

      <h1 className={styles.title}>LAN Party</h1>
      <div className={styles.lanContainer}>
        <div className={styles.descriere}>{lanPartyDescription}</div>
        {formular}
      </div>
      <div className={styles.buttonContainer}>
        {eventsAttended[lanPartyId] ? (
          <p className={styles.signedUp}>
            Ai fost înscris că participi la eveniment
          </p>
        ) : (
          <Button
            onClick={lanPartySignUp}
            variant="contained"
            sx={{
              backgroundColor: "#1A9A32",
              borderRadius: "1.5rem",
              "&:hover": {
                backgroundColor: "#136B1E",
              },
            }}
          >
            Sunt interesat
          </Button>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>
          {toastObj.current.message}
        </Alert>
      </Snackbar>

    </>
  );
}
export default LanParty;
