import React, { useCallback, useState } from "react";
import style from "./LanPartyStyles.module.css";


const lanPartySignUpDate = new Date("2024-11-01T00:00:00");

const LanPartyJocuri = ({ games, onNext }) => {


  let imgStyle = style.imgLan;
  let btnStyle = style.buttonFormLanParty;
  let btnStyleDisabled = style.buttonGreyedFormLanParty;
  let btnText = "Next";
  let disabledbtn = false;
  console.log(games);

  return (
    <div className={style.gameContainer}>
      {
       new Date() < lanPartySignUpDate ? (

          games.map((game) => {
            return (
              <div key={game.name} className={style.gameCard}>
                <img src={game.img} className={imgStyle} alt="" />
                <button
                  onClick={() => onNext(game)}
                  className={btnStyleDisabled}
                  disabled={true}
                >
                  {btnText}
                </button>
              </div>
            );
            }
          ) 
        ) : (
          games.map((game) => {
              imgStyle = style.imgLanNeAles;
              disabledbtn = true;
              btnStyle = style.buttonGreyedFormLanParty;
              btnText = "Closed";
         
  
          
              return (
                <div key={game.name} className={style.gameCard}>
                  <img src={game.img} className={imgStyle} alt="" />
                  <button
                    onClick={() => onNext(game)}
                    className={btnStyle}
                    disabled={disabledbtn}
                  >
                    {btnText}
                  </button>
                </div>
              );
          })
        )
      }
    
    </div>
  );
};

export default LanPartyJocuri;
