export const lolRules = (
  <div className="c3 c26 doc-content" style={{ textAlign: "justify" }}>
    <ol>
      <li dir="ltr">
        <h1 dir="ltr">
          Informaţii generale <br />
        </h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Finala competiţiei de League of Legends din cadrul proiectului ITFest 2024
      va avea loc la evenimentul numit LAN Party, ce se va desfăşura pe data de
      16 noiembrie 2024, la Discovery Arena.
    </p>
    <p dir="ltr">
      Înscrierea în acest concurs reprezintă acordul dat de a respecta
      regulamentul impus de organizatori şi arbitri, de a a se comporta în
      condiţii amiabile faţă de ceilalţi participanţi, de a fi înţelegători cu
      echipa de organizare şi de a juca în mod echitabil faţă de ceilalţi
      participanţi.
    </p>
    <br />
    <br />
    <ol start="2">
      <li dir="ltr">
        <h1 dir="ltr">Componenţa echipei</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Echipa va fi alcătuită din 5 membri, dintre care unul va fi ales căpitan
      de echipă. Acesta va fi legătura dintre echipă şi organizatori.
    </p>
    <p dir="ltr">Un jucător nu se poate înscrie în mai multe echipe.</p>
    <p dir="ltr">Căpitanul de echipă are următoarele responsabilități:</p>
    <p dir="ltr">● să comunice cu organizatorii în numele echipei;</p>
    <p dir="ltr">● să comunice cu restul echipelor în numele echipei;</p>
    <p dir="ltr">
      ● el reprezintă autoritatea finală pentru deciziile de echipă pe timpul
      turneului;
    </p>
    <p dir="ltr">● să comunice echipei informațiile necesare;</p>
    <p dir="ltr">● să reprezinte opiniile echipei cât mai bine.</p>
    <br />
    <br />
    <ol start="3">
      <li dir="ltr">
        <h1 dir="ltr">Metodologie înscrieri</h1>
      </li>
    </ol>
    <br />
    <h2 dir="ltr">A. Înscrieri</h2>
    <p dir="ltr">
      Înscrierile se vor realiza pe baza unui formular pe site-ul oficial al
      evenimentului, la secțiunea „LAN Party”. Căpitanul echipei are datoria de
      a completa formularul și de a asigura următoarele date:
    </p>
    <p dir="ltr">● Numele echipei;</p>
    <p dir="ltr">● ID-ul fiecărui membru al echipei in-game;</p>
    <br />
    <h2 dir="ltr">B. Eligibilitate</h2>
    <p dir="ltr">
      În cadrul competiției poate participa orice persoană fizică, care este
      elev de liceu sau student, cu vârsta de peste 16 ani. Verificarea acestei
      condiții va fi făcută înaintea de începerea evenimentului, iar persoanele
      care refuză vor fi descalificate împreună cu întreaga echipă.
    </p>
    <p dir="ltr">
      Nu sunt eligibili pentru a participa membrii echipei de coordonare a
      proiectului și nici membrii Biroului de Conducere și Executiv al
      Sindicatului Studenților din Cibernetică (SiSC).
    </p>
    <p dir="ltr">
      Participanții minori sunt obligați să prezinte o declarație semnată de
      către unul dintre părinții/tutorii legali. Prin semnarea acesteia se
      acceptă faptul că elevii minori vor participa la această competiție pe
      proprie răspundere. Această declarație este anexată la sfârșitul
      prezentului regulament, iar participanții vor trebui să o trimită
      completată pe adresa
      <a href="mailto:itfest@sisc.ro"> itfest@sisc.ro</a> cu cel puțin trei zile
      înainte de începerea concursului sau să o aibă asupra lor în format fizic
      în ziua desfășurării competiției. Neprezentarea acestei declarații în
      niciuna dintre cele două modalități duce la imposibilitatea participării
      minorului la concurs.
    </p>
    <br />
    <br />
    <ol start="4">
      <li dir="ltr">
        <h1 dir="ltr">Organizare competiţie</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">Competiţia va fi organizată în două etape:</p>
    <br />
    <ol>
      <li dir="ltr">
        <h2 dir="ltr">Preliminariile - online</h2>
      </li>
    </ol>
    <p dir="ltr">
      Acestea se vor organiza în perioada 9-10 noiembrie 2024, pe serverul
      Europe Nordic &amp; East (EU-NE). Echipele înscrise vor fi repartizate în
      mod aleator în grupe în funcție de numărul de echipe înscrise. În fiecare
      grupă, fiecare echipă va juca un meci cu toate celelalte echipe, urmând ca
      în cazul unei victorii să primească 1 punct, respectiv 0 puncte pentru
      înfrângere. Din fiecare grupă se vor califica primele două echipe, acestea
      urmând să participe la campionatul propriu-zis din cadrul LAN Party-ului.
      Dacă vor exista cazuri de egalitate, avantaj vor avea cei care au învins
      în meciul direct.
    </p>
    <br />
    <ol start="2">
      <li dir="ltr">
        <h2 dir="ltr">Campionatul propriu-zis - offline</h2>
      </li>
    </ol>
    <p dir="ltr">
      Acesta va fi organizat în data de 16 noiembrie 2024, fizic la Discovery Arena Tineretului,
      iar sistemul de joc va fi „Single Elimination”. Pentru stabilirea locului 3 se va juca un meci separat
      între pierzătorii semifinalelor.
    </p>
    <br />
    <ul>
      <li dir="ltr">
        <p dir="ltr">
          meciurile de pe bracket-ul principal vor fi de tipul Best Of 1;
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          finala turneului va fi jucată pe tipul Best of 3.
        </p>
      </li>
    </ul>
    <br />
    <p dir="ltr">
      Ne rezervăm dreptul de a înregistra aceste meciuri și de a face live
      streaming.
    </p>
    <br />
    <br />
    <ol start="5">
      <li dir="ltr">
        <h1 dir="ltr">Drepturile şi obligaţiile jucătorilor</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      (1) Pentru o mai bună organizare a evenimentului, jucătorii trebuie să dea
      dovadă de un comportament adecvat unei competiții. Astfel, pentru a atinge
      acest obiectiv, fiecare participant trebuie să respecte următoarele
      reguli:
    </p>
    <br />
    <p dir="ltr">
      1. Trebuie să dea dovadă de respect față de organizatori, ceilalți
      participanți și spectatori. De asemenea, să evite cu desăvârșire orice
      comportament inadecvat. În cazul în care un participant va avea un
      comportament inadecvat, va fi eliminat din competiție.
    </p>
    <br />
    <p dir="ltr">
      2. Trebuie să respecte programul oferit de organizatori și ajungă la timp
      la începutul fiecărui meci și să fie pregătiți pentru începerea lui. În
      cazul în care nu sunt prezenți, vor fi descalificați.
    </p>
    <br />
    <p dir="ltr">
      3. În cazul în care se observă o abatere de la reguli ea trebuie adusă la
      cunoștință unui organizator.
    </p>
    <br />
    <p dir="ltr">
      4. În cazul în care se observă un avantaj nelegitim într-un meci jucat,
      acesta trebuie adus la cunoștință unui organizator prin canalele de
      comunicare aflate la dispoziție.
    </p>
    <br />
    <p dir="ltr">
      5. Participanții trebuie să informeze Riot despre orice discrepanță între
      istoricul real al meciurilor și clasamentul postat de organizator, în
      cazul în care organizatorul nu remediază problema în urma unei sesizări.
    </p>
    <br />
    <p dir="ltr">
      6. Participanții trebuie să aibă contul de League of Legends pregătit pe
      serverul corespunzător, cu numărul de campioni necesari și paginile de
      rune.
    </p>
    <br />
    <p dir="ltr">
      7. Participanții trebuie să aducă orice echipament pe care organizatorii
      îl consideră necesar
    </p>
    <br />
    <p dir="ltr">8. Participanții trebuie să cunoască regulile.</p>
    <br />
    <br />
    <br />
    <p dir="ltr">
      (2) Organizatorul respectă și protejează confidențialitatea datelor
      personale din formularul de înscriere. Orice informație personală este
      prelucrată și folosită conform politicii de confidențialitate a datelor
      personale și nu va fi folosită în niciun mod care contravine acestei
      politici.
    </p>
    <br />
    <br />
    <br />
    <br />
    <ol start="6">
      <li dir="ltr">
        <h1 dir="ltr">Mecanica de competiţie</h1>
      </li>
    </ol>
    <br />
    <ol>
      <li dir="ltr">
        <h2 dir="ltr">Harta şi modul de joc</h2>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Harta de joc va fi Summoner’s Rift, iar modul de joc va fi Tournament
      Pick. Jucătorii se vor loga pe conturile lor, iar pe baza bracket-ului,
      aceștia își vor adăuga adversarii rând pe rând. La sfârșitul fiecărui
      meci, căpitanul echipei câștigătoare va face PrintScreen ecranului, pentru
      a dovedi victoria lor. Acestea se vor păstra până la eliminarea echipei
      din turneu. Tot aceștia vor anunța scorul final arbitrilor.
    </p>
    <br />
    <ol start="2">
      <li dir="ltr">
        <h2 dir="ltr">Proceduri pre-joc</h2>
      </li>
    </ol>
    <br />
    <p dir="ltr">a. Timpul de pregătire</p>
    <p dir="ltr">
      Timpul de pregătire reprezintă primele zece minute de dinaintea rundei și
      cele cinci minute dintre runde (acolo unde este cazul). Echipele ar trebui
      să utilizeze acest timp pentru a se asigura că sunt pregătite în
      totalitate.
    </p>
    <p dir="ltr">În acest timp, echipele trebuie să:</p>
    <p dir="ltr">
      ● Se conecteze la client folosind contul cu care s-au înregistrat, pe
      serverul corect;
    </p>
    <p dir="ltr">
      ● Creeze jocul, și dacă este necesar, să dea numele jocului oficialilor
      turneului;
    </p>
    <p dir="ltr">● Aleagă paginile de rune;</p>
    <p dir="ltr">● Testeze echipamentul și să îl configureze;</p>
    <p dir="ltr">
      ● Anunțe, în mod obligatoriu căpitanul, când sunt gata să înceapă. <br />
      <br />
    </p>
    <p dir="ltr">b. Defecțiuni tehnice ale echipamentelor</p>
    <p dir="ltr">
      Dacă un jucător se confruntă cu o problemă de natură tehnică cu orice
      echipament asigurat de organizatorii turneului în timpul de pregătire,
      acesta trebuie să informeze imediat arbitrul, astfel încât problema să
      poată fi remediată cât mai rapid.
    </p>
    <br />
    <p dir="ltr">c. Abandonul</p>
    <ul>
      <li dir="ltr">
        <p dir="ltr">
          Abandonul din turneu indică organizatorului faptul că o echipa nu mai
          doreşte să participe.
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          Echipele pot abandona turneul în orice moment prin anunţarea unui
          organizator.
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          Dacă o echipă nu se prezintă sau nu este logată la ora stabilită,
          organizatorul turneului poate elimina echipa.
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          Dacă ambele echipe nu s-au prezentat, ambele pot fi eliminate din
          bracket.
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          Dacă o persoană din echipă părăseşte turneul, ea nu poate fi eliminată
          din echipă până ce turneul nu s-a finalizat, iar premiile nu au fost
          acordate.
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          Dacă numărul de jucători ce renunţă dintr-o echipa depășeste numărul
          minim de persoane pentru un meci, echipa va fi eliminată din turneu.
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          Dacă o echipă doreşte să abandoneze sau dacă organizatorii turneului
          sunt nevoiți să elimine o echipă în timpul unui meci, echipa trebuie
          să abandoneze meciul curent înainte de a abandona oficial turneul.
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          Toate abandonurile trebuie să fie anunţate public către toţi
          concurenţii.
        </p>
      </li>
    </ul>
    <br />
    <br />
    <ol start="3">
      <li dir="ltr">
        <h2 dir="ltr">Proceduri în timpul jocului</h2>
      </li>
    </ol>
    <br />
    <p dir="ltr">a. Meciul de înregistrare (Game of Record / GOR)</p>
    <p dir="ltr">
      Un meci de înregistrare reprezintă un meci în care toți cei zece jucătorii
      sunt încărcați pe hartă și jocul a progresat până într-un punct de
      interacțiune semnificativă.
    </p>
    <p dir="ltr">
      După ce un joc ajunge la statutul de GOR, repornirea jocului va fi permisă
      numai în condiții limitate, cu noi picks-uri și banari.
    </p>
    <p dir="ltr">Condiții care stabilesc GOR sunt:</p>
    <p dir="ltr">
      ● Una dintre echipe lansează un atac sau o abilitate pe un minion, un
      creep din junglă, turete sau campioni inamici;
    </p>
    <p dir="ltr">
      ● Line-of-sight este stabilită între jucătorii din echipele opuse;
    </p>
    <p dir="ltr">● Interacţiunea cu jungla adversarului;</p>
    <p dir="ltr">● Cronometrul de joc ajunge la două minute.</p>
    <br />
    <p dir="ltr">b. Oprirea jocului</p>
    <p dir="ltr">
      Jucătorii sunt lăsați să întrerupă un joc în cazul în care un organizator
      al turneului își dă acordul sau ca parte din timpul lor de pauză alocat pe
      joc.
    </p>
    <p dir="ltr">
      În timpul oricărei pauze sau întrerupere, jucătorii nu pot părăsi zona de
      meci, cu excepția cazului în care au acordul unui oficial.
    </p>
    <p dir="ltr">
      Pauză directă: Organizatorii pot întrerupe un joc în cazul aparițiilor
      unor probleme.
    </p>
    <p dir="ltr">
      Pauză de echipă: Fiecare echipă primește un total de cinci minute de pauză
      în timpul unui meci din următoarele motive:
    </p>
    <p dir="ltr">● o deconectare neintenționată;</p>
    <p dir="ltr">
      ● o defecțiune hardware sau software (exemplu: defecțiune a monitorului
      sau a unui periferic sau un glitch în joc);
    </p>
    <p dir="ltr">● probleme fizice (exemplu: scaun rupt).</p>
    <p dir="ltr">
      În cazuri excepționale, jucătorii pot cere arbitrului turneului o pauză
      suplimentară după ce cele cinci minute au expirat. În acest caz, echipa
      trebuie să anunțe un arbitru și să continue meciul până când arbitrul
      decide dacă pauza va fi acordată. Dacă nu, jocul continuă.
    </p>
    <p dir="ltr">
      Reluarea jocului: Echipa care a cerut pauză nu poate relua jocul până când
      nu primește acordul unui arbitru și toți jucătorii sunt gata la posturile
      lor.
    </p>
    <p dir="ltr">
      Pauze neautorizate: Dacă un jucător întrerupe sau reia un joc fără
      permisiune, în afara timpului lor de pauză alocat, sau nu reia jocul în
      timpul lor alocat de pauză, aceștia vor fi supuși unor sancțiuni în
      conformitate cu regulile turneului.
    </p>
    <p dir="ltr">
      Comunicarea între coechipieri în timpul pauzelor: jucători au voie să
      discute despre gameplay sau strategie cu coechipierii lor.
    </p>
    <br />
    <br />
    <br />
    <ol start="4">
      <li dir="ltr">
        <h2 dir="ltr">Proceduri pentru sfârşitul jocului</h2>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Organizatorii au posibilitatea ca, în anumite condiții, să fie nevoiți să
      finalizeze forțat un joc sau meci înainte de finalizarea lui normală.
      Cauzele tipice pentru aceasta includ dificultăți logistice (cum ar fi
      închiderea locației).
    </p>
    <p dir="ltr">
      Organizatorii vor folosi următoarea metodă pentru a determina câștigătorii
      meciului în cazul în care o concluzie naturală a jocului nu este posibilă.
    </p>
    <p dir="ltr">
      Arbitrii trebuie să dea un preaviz de 10 minute jucătorilor în cazul în
      care intenționează să finalizeze forțat un joc/meci. Combinat cu avizul de
      10 minute, jocul trebuie să dureze cel puțin 20 de minute. Acest lucru
      înseamnă că preavizul nu poate fi dat decât abia după ce cronometrul de
      joc ajunge la 10 de minute. După ce expiră cele 10 minute, echipa cu cel
      mai mult aur este câștigătoarea jocului.
    </p>
    <p dir="ltr">
      În cazul în care este egalitate, arbitrii vor stabili echipa câștigătoare
      după următoarele metode, în ordinea următoare:
    </p>
    <p dir="ltr">1. Echipa cu cele mai multe turnuri distruse;</p>
    <p dir="ltr">2. Echipa cu cei mai mulți campioni omorâți;</p>
    <p dir="ltr">
      3. Se compară jucătorii cu cel mai mult aur strâns din fiecare echipă;
      Dacă este egalitate, se vor compara următorii cu cel mai mult aur strâns
      și așa mai departe.
    </p>
    <p dir="ltr">
      4. În cazul în care toate valorile de mai sus sunt la fel, se stabilește
      într-un mod aleator un câștigător al jocului prin aruncarea monezii sau
      altă metodă.
    </p>
    <p dir="ltr">
      Odată ce un meci s-a terminat, căpitanii din fiecare echipă trebuie să
      informeze oficialii turneului de rezultat. Ambele echipe trebuie să
      furnizeze scorul total în cazul în care meciul este mai mult decât un joc.
    </p>
    <br />
    <br />
    <ol start="7">
      <li dir="ltr">
        <h1 dir="ltr">Înregistrarea unui turneu</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Organizatorul turneului își rezervă dreptul de a înregistra competiţia
      utilizând live stream pentru meciurile din semifinală şi finală.
    </p>
    <p dir="ltr">
      Participanţii nu au dreptul de a înregistra meciurile pe care le joacă pe
      canalele private.
    </p>
    <br />
    <br />
    <ol start="8">
      <li dir="ltr">
        <h1 dir="ltr">Comportamentul în timpul turneului</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Atitudinea sportivă reprezintă responsabilitatea tuturor participanţilor
      la turneu şi promovează o competiţie sănătoasă între jucători. Regulile
      clare de conduită asigură o experiență plăcută de-a lungul turneului.
    </p>
    <p dir="ltr">
      Jucătorii ce încalcă regulamentul în timpul turneului vor fi sancționați.
    </p>
    <p dir="ltr">
      Toţi participanţii turneului, inclusiv jucătorii, arbitrii şi
      organizatorul trebuie să citească regulamentul.
    </p>
    <br />
    <ol start="9">
      <li dir="ltr">
        <h1 dir="ltr">Comunicarea dintre jucători</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Jucătorii au responsabilitatea de a urma indicaţiile notate. Comunicarea
      constantă cu oficialii turneului duce la o experienţă mult mai
      transparentă pentru orice persoană inclusă.
    </p>
    <p dir="ltr">
      Jucătorii pot comunica cu ceilalţi participanţi atâta timp cât nu încalcă
      vreo regulă menţionată anterior.
    </p>
    <p dir="ltr">
      Pe parcursul unui meci jucătorii nu au voie să comunice cu jucătorii
      adverși sau cu spectatorii.
    </p>
    <br />
    <br />
    <ol start="10">
      <li dir="ltr">
        <h1 dir="ltr">Identificarea echipei</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Jucătorii sunt încurajaţi să se reprezinte atât pe ei cât și echipa din
      care fac parte prin: numele echipei, tricouri, embleme, bannere etc.
    </p>
    <p dir="ltr">
      Arbitrul turneului are autoritatea finală faţă de orice comportament
      inadecvat a jucătorului. Prin comportament inadecvat se includ:
    </p>
    <p dir="ltr">● Orice referire la droguri sau produse de această natură;</p>
    <p dir="ltr">
      ● Promovarea materialelor legate de orice activitate neautorizată în zona
      turneului;
    </p>
    <p dir="ltr">
      ● Orice semn obscen, profan, vulgar, repulsiv, ofensator sau neplăcut ce
      descrie orice fel de lucru pe care o persoană îl poate considera
      inacceptabil;
    </p>
    <p dir="ltr">● Reclame adresate site-urilor sau produselor pornografice;</p>
    <p dir="ltr">
      ● Cuvinte sau gesturi care ar putea să deranjeze adversarul;
    </p>
    <p dir="ltr">
      ● Majoritatea ID-urilor neadecvate de identificare al unor echipe pot fi
      schimbate în mod amical.
    </p>
    <br />
    <br />
    <ol start="11">
      <li dir="ltr">
        <h1 dir="ltr">Dispoziţii finale</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Participanții au obligativitatea de a respecta prezentul regulament.
      Organizatorii își rezervă dreptul de a aduce modificări în prezentul
      regulament, informând participanții în timp util.
    </p>
    <div>
      <br />
    </div>
    <ol start="11">
      <li dir="ltr">
        <h1 dir="ltr">Anexă</h1>
      </li>
    </ol>
    <a
      href="https://docs.google.com/document/d/1gl2l7HGMqaxjleEBF5JeJJad-X4jXkvXD0agwwMNaiA/edit?usp=sharing"
      target="_blank"
      dir="ltr"
    >
      <u>Descarcă declarația pe propria răspundere</u>
    </a>
    <br />
    <br />
    <br />
    <br />
  </div>
);
