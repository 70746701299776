import React, { useEffect, useState } from "react";
import styles from "./DashboardPage.module.css";
import AttendanceStatus from "../../../components/attendanceStatus/AttendanceStatus";
import HackathonTeam from "../../../components/hackathonTeam/HackathonTeam";
import UserTable from "../../../components/userTable/UserTable";
import axios from "axios";
import { APIURL } from "../../../constants";

function getAllCvs() {
  console.log("descarca cv uri")
  // Axios.get(`${APIURL}/cv`, {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  // }).then(() => {
  //   toastObj.current.type = "success";
  //   toastObj.current.message = "CV-ul tău a fost schimbat cu succes";
  // }).catch((error) => {
  //   console.log(error)
  //   toastObj.current.type = "error"
  //   toastObj.current.message = "Eroare de server la schimbarea CV-ului";
  // }).finally(() => {
  //   setCV(null)
  //   setOpenToast(true)
  // })
}

function DashboardPage() {
  const [echipeAdobe, setEchipeAdobe] = useState([]);
  const [echipeEA, setEchipeEA] = useState([]);
  const [echipeLoL, setEchipeLoL] = useState([]);
  const [echipeCS2, setEchipeCS2] = useState([]);

  useEffect(() => {
    axios.get(`${APIURL}/hackathon/getAllTeams/4`, {
      withCredentials: true,
    }).then((res) => {
      setEchipeAdobe(res.data);
      console.log(res);
    })

    axios.get(`${APIURL}/hackathon/getAllTeams/5`, {
      withCredentials: true,
    }).then((res) => {
      setEchipeEA(res.data);
    })

    axios.get(`${APIURL}/hackathon/getAllTeams/6`, {
      withCredentials: true,
    }).then((res) => {
      setEchipeLoL(res.data);
      console.log(res.data);
    })

    axios.get(`${APIURL}/hackathon/getAllTeams/7`, {
      withCredentials: true,
    }).then((res) => {
      setEchipeCS2(res.data);
    })
  }, [])

  return (
    <>
      <div className={styles.adminContainer}>
        {/* ATTENDANCES */}
        <div className={styles.mainpart}>
          <div className={[styles.paper, styles.attendenceWrapper].join(" ")}>
            <h1>Statistici Participanti</h1>

            <div className={styles.dashboardEvents}>
              <h2>JobHub</h2>
              <AttendanceStatus eventID={1} />
            </div>

            <div className={styles.dashboardEvents}>
              <h2>Career Launch</h2>
              <AttendanceStatus eventID={2} />
            </div>

            <div className={styles.dashboardEvents}>
              <h2>Workshop</h2>
              <AttendanceStatus eventID={3} />
            </div>

            {/* <div className={styles.dashboardEvents}>
              <h2>Hackathon EA</h2>
              <AttendanceStatus eventID={4} />
            </div> */}

            <div className={styles.dashboardEvents}>
              <h2>Hackathon Adobe</h2>
              <AttendanceStatus eventID={4} />
            </div>
            <div className={styles.dashboardEvents}>
              <h2>Hackathon EA</h2>
              <AttendanceStatus eventID={5} />
            </div>

            <div className={styles.dashboardEvents}>
              <h2>LAN LOL</h2>
              <AttendanceStatus eventID={6} />
            </div>

            <div className={styles.dashboardEvents}>
              <h2>LAN CS2</h2>
              <AttendanceStatus eventID={7} />
            </div>

            <div className={styles.dashboardEvents}>
              <h2>LAN FiFA</h2>
              <AttendanceStatus eventID={8} />
            </div>

          </div>





          {/* HACKATHON TEAMS */}
          <div className={[styles.paper, styles.hackathonTeamsWrapper].join(" ")}>
            <h1>Echipe Hackathon Adobe</h1>
            <div className={styles.hackathonTeamsContainer}>
              {
                (echipeAdobe.length > 0) ? (
                  echipeAdobe.map((echipa) => (
                    console.log(echipa, "ECHIPE"),
                    <HackathonTeam key={echipa.id} teamID={echipa} captainId={echipa.captainId}/>
                  ))
                ) : (
                  <p>0 Echipe</p>
                )
              }
            </div>

            <h1>Echipe Hackathon EA</h1>
            <div className={styles.hackathonTeamsContainer}>
              {
                (echipeEA.length > 0) ? (
                  echipeEA.map((echipa) => (
                    <HackathonTeam key={echipa.id} teamID={echipa} captainId={echipa.captainId}/>
                  ))
                ) : (
                  <p>0 Echipe</p>
                )
              }
            </div>

            <h1>Echipe LAN LoL</h1>
            <div className={styles.hackathonTeamsContainer}>
              {
                (echipeLoL.length > 0) ? (
                  echipeLoL.map((echipa) => (
                    <HackathonTeam key={echipa.id} teamID={echipa} captainId={echipa.captainId}/>
                  ))
                ) : (
                  <p>0 Echipe</p>
                )
              }
            </div>

            <h1>Echipe LAN CS2</h1>
            <div className={styles.hackathonTeamsContainer}>
              {
                (echipeCS2.length > 0) ? (
                  echipeCS2.map((echipa) => (
                    <HackathonTeam key={echipa.id} teamID={echipa} captainId={echipa.captainId}/>
                  ))
                ) : (
                  <p>0 Echipe</p>
                )
              }
            </div>

          </div>


        </div>


        {/* USER TABLE */}
        <div className={styles.userTableWrapper}>
          <UserTable>

          </UserTable>
          <div className={styles.containerSaveCVBtn}>
            <button className={styles.saveCVBtn} onClick={getAllCvs}>
              Salvează CV
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;