import React, { useEffect, useState } from "react";
// Uncomment this and the use of it below, and install the package so it works
import styles from "./QRPage.module.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Scanner, useDevices } from '@yudiel/react-qr-scanner';
import axios from "axios";
import { APIURL } from "../../../constants.js";

const options = [
  {
    value: "1",
    label: "JobHub",
  },
  {
    value: "2",
    label: "Workshop",
  },
  {
    value: "4",
    label: "Hackathon Adobe",
  },
  {
    value: "5 ",
    label: "Hackathon Nable",
  },
  {
    value: "11",
    label: "LAN Party",
  },
  {
    value: "6",
    label: "League of Legends",
  }, {
    value: "8",
    label: "EA FC 25",
  }, {
    value: "7",
    label: "Counter Strike 2",
  },

];

const selectStyles = {
  ".MuiSelect-select": {
    border: "1px solid var(--dark-green) !important",
  },

  "& .MuiFormLabel-root": {
    color: "var(--dark-green)", // Customize text color
  },

  "& .MuiInputBase-input": {
    color: "var(--dark-green)", // Customize text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "green", // Customize the border color
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red", // Customize the border color when focused
  },
};

const QRPage = () => {
  const [data, setData] = useState(null);
  const [paused, setPaused] = useState(false);
  const [event, setEvent] = useState();


  const handleValidate = () => {

    setPaused(false);
    console.log(event)
    axios.post(`${APIURL}/qr/scan`, {
      eventId: event,
      userId: data[0].rawValue,
    }).then((res) => {
      console.log(res.data);
      alert(res.data.message);

    }).catch((err) => {
      console.log(err);
      alert(err.response.data);
    })
  }

  const handleOnScan = (result) => {
    setData(result);
    setPaused(true);
  }

  return (
    <>
      <div className={styles.containerQRPage}>
        {
          !paused && (
            <Scanner
              onScan={handleOnScan}
              constraints={{ video: { facingMode: { exact: 'enviornment' } } }}
              classNames={styles.scanner}
              paused={paused}
              styles={{
                container: {
                  width: "25rem",
                  padding: "2rem",
                }
              }}
            />
          )

        }


        <TextField
          id="outlined-select-currency"
          select
          fullWidth
          defaultValue=""
          label="Select"
          helperText="Alege evenimentul"
          sx={selectStyles}
          onChange={(e) => setEvent(e.target.value)}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>


        <button onClick={handleValidate} className={styles.QRPageButton}> Validate</button>

      </div>
    </>
  );
};

export default QRPage;
