import styles from "./LanPartyForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState, useRef, useCallback } from "react";
import { Alert, Snackbar, Slide } from "@mui/material";
import fifapoza from "../../resources/FIFA.png";
import Axios from "axios";
import useStore from "../../store/userStore";
import { APIURL } from "../../constants";
import CustomModal from "../modal/CustomModal";
import { cs } from "../../staticData/lanParty/cs";
import { fifa } from "../../staticData/lanParty/fifa";
import { lol } from "../../staticData/lanParty/lol";

const fifaId = 8;

const rules = [lol, cs, fifa];

function LanPartyForm({ onNext, gameId, onBack }) {
  const user = useStore((state) => state.user);

  const [nrCoechipieri, setNrCoechipieri] = useState(0);
  const [numeCoechipieri, setNumeCoechipieri] = useState([]);
  const [idCoechipieri, setIdCoechipieri] = useState([user.id]);
  const [formName, setFormName] = useState("");
  const [numeEchipa, setNumeEchipa] = useState("");
  const [addEventAttended] = useStore((state) => [state.addEventAttended]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "info",
    message: "",
  });

  const incrementCoechipieri = () => {
    setNrCoechipieri(nrCoechipieri + 1);
  };

  const onAddCoechipier = (id) => {
    if (isNaN(id) || id <= 0) {
      toastObj.current.message = "ID-ul introdus invalid";
    } else {
      if (nrCoechipieri < 4) {
        let ok = true;
        idCoechipieri.forEach((idCoechipier) => {
          if (idCoechipier === id) {
            toastObj.current.message = "ID-ul introdus deja există";
            toastObj.current.type = "error";
            setOpenToast(true);
            ok = false;
          }
        });

        Axios.get(`${APIURL}/attendance/getParticipants/${gameId}`, {
          withCredentials: true,
        }).then((res) => {
          console.log(res.data);
          res.data.forEach((attendance) => {
            console.log(attendance.userId, id, nrCoechipieri);
            if (attendance.userId == id) {
              toastObj.current.message = "Userul este deja inscris la acest eveniment";
              toastObj.current.type = "error";
              setOpenToast(true);
              ok = false;
            }
          });
        }).then(() => {

          if (ok) {
            Axios.get(`${APIURL}/user/getUserByID/${id}`, {
              withCredentials: true,
            })
              .then((response) => {
                if (response.data.id === user.id) {
                  toastObj.current.message = "Nu te poți adăuga pe tine însuți";
                  toastObj.current.type = "error";
                  setOpenToast(true);
                  return;
                }
                let numeNou =
                  response.data.lastName + "-" + response.data.firstName;
                setNumeCoechipieri((numeCoechipieri) => [
                  ...numeCoechipieri,
                  nrCoechipieri + 1 + ": " + numeNou + " ",
                ]);
                setFormName("");
                incrementCoechipieri();
                setIdCoechipieri((idCoechipieri) => [...idCoechipieri, id]);
              })
              .catch((error) => {
                toastObj.current.message = "Niciun user găsit cu acest ID";
                toastObj.current.type = "error";
                setOpenToast(true);
                console.log(error);
              });
          }
         
        });
      } else {
        toastObj.current.message = "Prea multi coechipieri";
        toastObj.current.type = "error";
        setOpenToast(true);
      }
    }
  }

    const handleInscriere = () => {
      if (nrCoechipieri === 4 && numeEchipa.length >= 3) {
        Axios.post(`${APIURL}/lan/`, {
          eventId: gameId,
          name: numeEchipa,
          membersIDs: idCoechipieri,
        })
          .then((response) => {
            console.log(response);
            onNext(gameId);
          })
          .catch((error) => {
            console.log(error);
            toastObj.current.message = error.response.data.message;
            toastObj.current.type = "error";
            setOpenToast(true);
            console.log(error);
          });
      } else {
        if (gameId === 8) {
          Axios.post(`${APIURL}/attendance/${user.id}`, {
            eventId: fifaId,
          })
            .then((res) => {
              toastObj.current.type = "success";
              toastObj.current.message = res.data;
              setOpenToast(true);
              addEventAttended(fifaId);
            })
            .catch((error) => {
              toastObj.current.message = "Eroare de server";
              toastObj.current.type = "error";
              setOpenToast(true);

              console.log(error);
            });
        } else {
          toastObj.current.message = "Prea puțini coechipieri sau nume echipă prea scurt";
          setOpenToast(true);
        }
      }
    };

    const onPressRegulament = (e) => {
      e.preventDefault();
      alert("Regulament");
    };

    const handleEnter = (e) => {
      if (e.keyCode === 13) {
        onAddCoechipier(formName);
      }
    };

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openToast}
          autoHideDuration={3000}
          onClose={() => setOpenToast(false)}
          TransitionComponent={Slide}
        >
          <Alert severity={toastObj.current.type}>
            {toastObj.current.message}
          </Alert>
        </Snackbar>

        <div onKeyDown={handleEnter} className={styles.containerFormLanParty}>
          <button onClick={onBack} className={styles.buttonBack}>{`<`}</button>
          <form onSubmit={onPressRegulament} className={styles.formLanParty}>
            {gameId !== 8 ? (
              <>
                <h2 style={{ color: "#FFAD4A" }}>Formular echipă</h2>
                <div className={styles.containerMidFormLanParty}>
                  <label>Nume Echipă</label>
                  <input
                    type="text"
                    className={styles.inputFormLanParty}
                    onChange={(e) => setNumeEchipa(e.target.value)}
                  />
                </div>
                <div className={styles.containerMidFormLanParty}>
                  <label>ID coechipier { }</label>
                  <div className={styles.containerAddMembru}>
                    <input
                      type="text"
                      className={styles.inputFormLanPartyAddMembru}
                      value={formName}
                      onChange={(e) => setFormName(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className={styles.plusLanParty}
                      icon={faPlus}
                      onClick={() => onAddCoechipier(formName)}
                    />
                  </div>
                  <div className={styles.listaNumeCoechipieri}>
                    {numeCoechipieri}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.formularFifa}>
                  <h2 style={{ color: "#FFAD4A" }}>Formular înscriere</h2>
                  <img src={fifapoza} className={styles.imgLan} alt="" />
                </div>
              </>
            )}
          </form>
          <div className={styles.containerBtnFormLanParty}>
            <button
              className={`${styles.buttonRegFormLanParty} ${styles.buttonFormLanParty}`}
              onClick={openModal}
            >
              Regulament{" "}
            </button>
            <CustomModal
              onClose={closeModal}
              isOpen={isModalOpen}
              title={rules[gameId - 6].rules.title}
            >
              <p className={styles.rulesContent}>
                {rules[gameId - 6].rules.text}
              </p>
            </CustomModal>
            <button
              className={`${(nrCoechipieri === 4 && numeEchipa.length >= 3) || gameId === 8
                ? styles.buttonInsFormLanParty
                : styles.buttonInsFormLanPartyClosed
                } ${styles.buttonFormLanParty}`}
              onClick={handleInscriere}
            >
              Înscrie-te
            </button>
          </div>
        </div>
      </>
    );
  }

export default LanPartyForm;

