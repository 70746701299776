export const csRules = (
  <div className="c3 c26 doc-content" style={{ textAlign: "justify" }}>
    <ol>
      <li dir="ltr">
        <h1 dir="ltr">Informaţii generale</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">
      Competiţia Counter-Strike 2 din cadrul proiectului ITFest 2024 va avea loc
      la evenimentul numit LAN Party, ce se va desfăşura pe data de 16 noiembrie
      2024, la Discovery Arena.
    </p>
    <p dir="ltr">
      Înscrierea în acest concurs reprezintă acordul dat de a respecta
      regulamentul impus de organizatori şi administratori, de a se comporta în
      condiţii amiabile faţă de ceilalţi participanţi, de a fi înţelegători cu
      echipa de organizare şi de a juca în mod echitabil faţă de ceilalţi
      participanţi.
    </p>
    <br />
    <br />
    <h1 dir="ltr">2. Componenţa echipei</h1>
    <br />
    <p dir="ltr">
      Echipa va fi alcătuită din 4 membri + 1 căpitan, aceştia fiind aleşi la
      momentul înscrierii. Totalul echipelor care vor ajunge fizic, la LAN
      Party, va fi în număr de opt.
    </p>
    <p dir="ltr">Un jucător nu se poate înscrie în mai multe echipe.</p>
    <p dir="ltr">
      Căpitanul de echipă va fi legătura dintre echipă şi organizatori, aceştia
      având următoarele responsabilităţi:
    </p>
    <p dir="ltr">● să comunice cu organizatorii în numele echipei;</p>
    <p dir="ltr">● să comunice cu restul echipelor în numele echipei;</p>
    <p dir="ltr">
      ● el reprezintă autoritatea finală pentru deciziile de echipă pe timpul
      turneului;
    </p>
    <p dir="ltr">● să comunice echipei informațiile necesare;</p>
    <p dir="ltr">● să reprezinte opiniile echipei cât mai bine.</p>
    <br />
    <br />
    <h1 dir="ltr">3. Metodologie înscrieri</h1>
    <br />
    <ol>
      <li dir="ltr">
        <h2 dir="ltr">A. Înscrieri</h2>
      </li>
    </ol>
    <p dir="ltr">
      Înscrierile se vor realiza pe baza unui formular pe site-ul oficial al
      evenimentului, la secțiunea „LAN Party”. Echipele vor fi înscrise de o
      singură persoană, căpitanul echipei, pe site-ul ITFest. Ulterior se va
      face un grup cu căpitanii echipelor și li se vor distribui linkul pentru
      perioada eliminatorie desfășurată pe FACEIT.
    </p>
    <br />
    <ol start="2">
      <li dir="ltr">
        <h2 dir="ltr">B. Eligibilitate</h2>
      </li>
    </ol>
    <p dir="ltr">
      În cadrul competiției poate participa orice persoană fizică, care este
      elev de liceu sau student, cu vârsta de peste 16 ani. Verificarea acestei
      condiții va fi făcută înaintea de începerea evenimentului, iar persoanele
      care refuză vor fi descalificate împreună cu întreaga echipă.
    </p>
    <p dir="ltr">
      Nu sunt eligibili pentru a participa membrii echipei de coordonare a
      proiectului și nici membrii Biroului de Conducere și Executiv al
      Sindicatului Studenților din Cibernetică (SiSC).
    </p>
    <p dir="ltr">
      Participanții minori sunt obligați să prezinte o declarație semnată de
      către unul dintre părinții/tutorii legali. Prin semnarea acesteia se
      acceptă faptul că elevii minori vor participa la această competiție pe
      proprie răspundere. Această declarație este anexată la sfârșitul
      prezentului regulament, iar participanții vor trebui să o trimită
      completată pe adresa
      <a href="mailto:itfest@sisc.ro"> itfest@sisc.ro</a> cu cel puțin trei zile
      înainte de începerea concursului sau să o aibă asupra lor în format fizic
      în ziua desfășurării competiției. Neprezentarea acestei declarații în
      niciuna dintre cele două modalități duce la imposibilitatea participării
      minorului la concurs.
    </p>
    <br />
    <br />
    <ol start="4">
      <li dir="ltr">
        <h1 dir="ltr">Organizare competiţie</h1>
      </li>
    </ol>
    <br />
    <p dir="ltr">Competiţia va fi organizată în două etape:</p>
    <br />
    <ol>
      <li dir="ltr">
        <h2 dir="ltr">Preliminariile - online</h2>
      </li>
    </ol>
    <p dir="ltr">
      Acestea se vor organiza în perioada 9-10 noiembrie 2024, pe serverul
      Europe Nordic &amp; East (EU-NE). Echipele înscrise vor fi repartizate în
      mod aleator în grupe în funcție de numărul de echipe înscrise. În fiecare
      grupă, fiecare echipă va juca un meci cu toate celelalte echipe, urmând ca
      în cazul unei victorii să primească 1 punct, respectiv 0 puncte pentru
      înfrângere. Din fiecare grupă se vor califica primele două echipe, acestea
      urmând să participe la campionatul propriu-zis din cadrul LAN Party-ului.
      Dacă vor exista cazuri de egalitate, avantaj vor avea cei care au învins
      în meciul direct.
    </p>
    <br />
    <ol start="2">
      <li dir="ltr">
        <h2 dir="ltr">Campionatul propriu-zis - offline</h2>
      </li>
    </ol>
    <p dir="ltr">
      Acesta va fi organizat în data de 16 noiembrie 2024, fizic la Discovery Arena Tineretului,
      iar sistemul de joc va fi „Single Elimination”. Pentru stabilirea locului 3 se va juca un meci separat
      între pierzătorii semifinalelor. Finala turneului va fi jucată Best of 3.
    </p>
    <br />
    <br />
    <p dir="ltr">
      Ne rezervăm dreptul de a înregistra aceste meciuri și de a face live
      streaming.
    </p>
    <br></br>
    <br></br>
    <h1 dir="ltr">4. Drepturile şi obligaţiile jucătorilor</h1>
    <br />
    <p dir="ltr">
      (1) Pentru o mai bună organizare a evenimentului, jucătorii trebuie să dea
      dovadă de un comportament adecvat unei competiții. Astfel, pentru a atinge
      acest obiectiv, fiecare participant trebuie să respecte următoarele
      reguli:
    </p>
    <br />
    <p dir="ltr">
      1. Trebuie să dea dovadă de respect față de organizatori, ceilalți
      participanți și spectatori. De asemenea, să evite cu desăvârșire orice
      comportament inadecvat. În cazul în care un participant va avea un
      comportament inadecvat, va fi eliminat din competiție.
    </p>
    <br />
    <p dir="ltr">
      2. Trebuie să respecte programul oferit de organizatori și ajungă la timp
      la începutul fiecărui meci și să fie pregătiți pentru începerea lui. În
      cazul în care nu sunt prezenți, vor fi descalificați.
    </p>
    <br />
    <p dir="ltr">
      3. În cazul în care se observă o abatere de la reguli ea trebuie adusă la
      cunoștință unui organizator prin canalele de comunicare aflate la
      dispoziție.
    </p>
    <br />
    <p dir="ltr">
      4. În cazul în care se observă un avantaj nelegitim într-un meci jucat,
      acesta trebuie adus la cunoștință unui organizator prin canalele de
      comunicare aflate la dispoziție.
    </p>
    <br />
    <p dir="ltr">
      5. Participanții trebuie să aducă orice echipament pe care organizatorii
      îl consideră necesar.
    </p>
    <br />
    <p dir="ltr">6. Participanții trebuie să cunoască regulile.</p>
    <br />
    <p dir="ltr">
      (2) Organizatorul respectă și protejează confidențialitatea datelor
      personale din formularul de înscriere. Orice informație personală este
      prelucrată și folosită conform politicii de confidențialitate a datelor
      personale și nu va fi folosită în niciun mod care contravine acestei
      politici.
    </p>
    <br />
    <br />
    <h1 dir="ltr">5. Mecanica de competiţie</h1>
    <br />
    <p dir="ltr">
      Sistemul turneului este unul eliminatoriu la o înfrângere acumulată până
      în etapa finală, care va avea un sistem de Best Of 3.
    </p>
    <p dir="ltr">
      Prima rundă va fi cea de cuţite, pentru a stabili echipele pentru prima
      jumătate a meciului (Counter - Terrorist’s/ Terrorist’s). Căpitanul
      echipei câştigătoare a rundei de cuţite va alege prin scrierea în chat a
      deciziei: !stay / !switch .
    </p>
    <p dir="ltr">
      Se va juca o singură hartă pe meci, care se va alege înainte de începerea
      fiecărui meci prin vot de tip veto/eliminare de către căpitanii ambelor
      echipe. În cazul în care meciul va fi egal (12-12), se vor juca prelungiri
      de tipul best of 6 rounds, fiecare jucător primind la început câte
      10.000$. Ordinea echipelor va rămâne cea dinaintea prelungirilor,
      schimbându-se între ele după primele 3 runde.
    </p>
    <p dir="ltr">
      Pentru a începe meciul, este necesară conectarea celor 10 jucători şi
      confirmarea fiecăruia că este pregătit de startul meciului.
    </p>
    <p dir="ltr">
      NU este permis spam-ul, flame-ul sau injuriile aduse în chat. Ideal ar fi
      ca doar căpitanii echipelor să comunice cu adminul pe server. Jucătorii
      trebuie să dea dovadă de bun simţ şi respect reciproc. Abaterea de la
      această regulă poate duce la descalificare echipei.
    </p>
    <br />
    <h1 dir="ltr">6. Setări</h1>
    <br />
    <p dir="ltr">
      Setările de pe server vor fi cele oficiale FACEIT. Hărţile dispuse la
      votul veto sunt: de_ancient, de_anubis, de_dust2, de_nuke, de_inferno,
      de_mirage, de_vertigo.
    </p>
    <br />
    <br />
    <h1 dir="ltr">7. Înregistrarea unui turneu</h1>
    <br />
    <p dir="ltr">
      Organizatorul turneului își rezervă dreptul de a înregistra competiţia
      utilizând live stream pentru meciurile din semifinală şi finală.
    </p>
    <p dir="ltr">
      Participanţii nu au dreptul de a înregistra meciurile pe care le joacă
      pentru canalele private.
    </p>
    <br />
    <br />
    <h1 dir="ltr">8. Comportamentul în timpul jocului</h1>
    <br />
    <p dir="ltr">
      Atitudinea sportivă reprezintă responsabilitatea tuturor participanţilor
      la turneu şi promovează o competiţie sănătoasă între jucători. Regulile
      clare de conduită asigură o experiență plăcută de-a lungul turneului.
    </p>
    <p dir="ltr">
      Jucătorii ce încalcă regulamentul în timpul turneului vor fi sancţionați.
    </p>
    <p dir="ltr">
      Toţi participanţii turneului, inclusiv jucătorii, arbitrii şi
      organizatorul trebuie să citească și să cunoască regulamentul.
    </p>
    <br />
    <br />
    <h1 dir="ltr">9. Comunicarea dintre jucători</h1>
    <br />
    <p dir="ltr">
      Jucătorii au responsabilitatea de a urma indicaţiile notate. Comunicarea
      constantă cu oficialii turneului duce la o experienţă mult mai
      transparentă pentru orice persoană inclusă.
    </p>
    <p dir="ltr">
      Jucătorii pot comunica cu ceilalţi participanţi atâta timp cât nu încalcă
      vreo regulă menţionată anterior.
    </p>
    <p dir="ltr">
      Pe parcursul unui meci jucătorii nu au voie să comunice cu jucătorii
      adverși sau cu spectatorii.
    </p>
    <br />
    <br />
    <h1 dir="ltr">10. Identificarea echipei</h1>
    <br />
    <p dir="ltr">
      Jucătorii sunt încurajaţi să se reprezinte atât pe ei cât și echipa din
      care fac parte prin: numele echipei, tricouri, embleme, bannere etc.
    </p>
    <p dir="ltr">
      Arbitrul turneului are autoritatea finală faţă de orice comportament
      inadecvat al jucătorului. Prin comportament inadecvat se includ:
    </p>
    <p dir="ltr">● Orice referire la droguri sau produse de această natură;</p>
    <p dir="ltr">
      ● Promovarea materialelor legate de orice activitate neautorizată în zona
      turneului;
    </p>
    <p dir="ltr">
      ● Orice semn obscen, profan, vulgar, repulsiv, ofensator sau neplăcut ce
      descrie orice fel de lucru pe care o persoană îl poate considera
      inacceptabil;
    </p>
    <p dir="ltr">● Reclame adresate site-urilor sau produselor pornografice;</p>
    <p dir="ltr">
      ● Cuvinte sau gesturi care ar putea să deranjeze adversarul;
    </p>
    <p dir="ltr">
      ● Majoritatea ID-urilor neadecvate de identificare al unor echipe pot fi
      schimbate în mod amical.
    </p>
    <br />
    <br />
    <h1 dir="ltr">11. Dispoziţii finale</h1>
    <br />
    <p dir="ltr">
      Participanții au obligativitatea de a respecta prezentul regulament.
      Organizatorii își rezervă dreptul de a aduce modificări în prezentul
      regulament, informând participanții în timp util.
    </p>
    <ol start="11">
      <li dir="ltr">
        <h1 dir="ltr">Anexă</h1>
      </li>
    </ol>
    <a
      href="https://docs.google.com/document/d/1gl2l7HGMqaxjleEBF5JeJJad-X4jXkvXD0agwwMNaiA/edit?usp=sharing"
      target="_blank"
      dir="ltr"
    >
      <u>Descarcă declarația pe propria răspundere</u>
    </a>
    <div>
      <br />
    </div>
  </div>
);
