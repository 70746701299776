export const workshopDescription = (
  <div class="c1 doc-content">
    <p >
      <span>
      &emsp; Într-o eră digitală ce este în continuă evoluție, alege să fii 
      arhitectul propriei tale transformări! Aici nu doar că vei învăța tainele tehnologiei,
       ci vei și construi punți către viitorul pe care îl creezi.
      </span>
    </p>
    <p>
      <span>
      &emsp; Rezervă-ți timp pentru a-ți alimenta curiozitatea și a porni într-o
       călătorie captivantă a inovației și cunoașterii, unde fiecare pas 
       te aduce mai aproape de următorul mare salt în cariera ta!
       {/* &emsp;Te așteptăm pe 29 octombrie, la ora 18:00, la sediul companiei Adobe */}
        {/* pentru a te lăsa descoperIT! */}
      </span>
    </p>
    <p> 
      Alătură-te pentru a descoperi cum e să fii un intern al companiei,
      cum să compui cel mai top CV, dar și cum să te pregătești pentru un interviu.
    </p>
    <a href="https://us06web.zoom.us/j/84045680023?pwd=9RSS7aR7HrQBSarFx4gmVmjJgS12Q2.1" target="_blank" style={{fontSize: "40px"}}><u>Link Meeting Zoom</u></a>
  </div>
);
