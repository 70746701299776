export const fifaRules = (
  <div className="c3 c26 doc-content" style={{ textAlign: "justify" }}>
    <ol>
      <li dir="ltr">
        <h1 dir="ltr">Informații generale</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Competiția de FIFA din cadrul proiectului ITFest 2024 va avea loc în
      cadrul LAN Party-ului ce se va desfășura pe data de 16 noiembrie 2024, la
      Discovery Arena.
    </p>
    <p dir="ltr">
      (2) Înscrierea în acest concurs reprezintă acordul dat de a respecta
      regulamentul impus de organizatori și arbitri, de a te comporta în
      condiții amiabile față de ceilalți participanți, de a fi înțelegător cu
      echipa de organizare și de a juca în mod echitabil față de ceilalți
      participanți.
    </p>
    <br />
    <ol start="2">
      <li dir="ltr">
        <h1 dir="ltr">Sistemul de joc</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Versiunea de joc folosită va fi FIFA 25, iar competiția va fi de tipul
      1v1.
    </p>
    <p dir="ltr">
      (2) Fiecare jucător își va alege o echipă principală. Jucătorul va avea la
      dispoziție pe durata întregii competiții doar echipele alese pentru a-și
      disputa meciurile. De asemenea, sunt permise doar echipele de club, nu și
      cele naționale.
    </p>
    <br />
    <ol start="3">
      <li dir="ltr">
        <h1 dir="ltr">Metodologia de înscrieri</h1>
      </li>
    </ol>
    <h3 dir="ltr">A. Înscrieri</h3>
    <h3 dir="ltr">
      Înscrierile se vor realiza pe baza unui formular pe site-ul oficial al
      evenimentului, la secțiunea „LAN Party”
    </h3>
    <br />
    <h3 dir="ltr">B. Eligibilitate</h3>
    <p dir="ltr">
      (1) În cadrul competiției poate participa orice persoană fizică, care este
      elev de liceu sau student, cu vârsta de peste 16 ani. Verificarea acestei
      condiții va fi făcută înaintea de începerea evenimentului, iar persoanele
      care refuză vor fi descalificate.
    </p>
    <p dir="ltr">
      (2) Participanții minori sunt obligați să prezinte o declarație semnată de
      către unul dintre părinții/tutorii legali. Prin semnarea acesteia se
      acceptă faptul că elevii minori vor participa la această competiție pe
      proprie răspundere. Această declarație este anexată la sfârșitul
      prezentului regulament, iar participanții vor trebui să o trimită
      completată pe adresa
      <a href="mailto:itfest@sisc.ro"> itfest@sisc.ro</a> cu cel puțin trei zile
      înainte de începerea concursului sau să o aibă asupra lor în format fizic
      în ziua desfășurării competiției. Neprezentarea acestei declarații în
      niciuna dintre cele două modalități duce la imposibilitatea participării
      minorului la concurs.
    </p>
    <ol start="4">
      <li dir="ltr">
        <h1 dir="ltr">Organizarea competiției</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Organizatorii vor asigura participanților console, iar jocul oferit va
      fi EA FC 25. O repriză va dura 5 minute.
    </p>
    <p dir="ltr">
      (2) Turneul va avea 32 de participanti și se va desfășura pe structura
      „Single Elimination”, cu un meci separat pentru a stabili locul 2, respectiv 3.
    </p>
    <br />
    <ol start="5">
      <li dir="ltr">
        <h1 dir="ltr">Drepturile și obligațiile jucătorilor</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Pentru o mai bună organizare a evenimentului, jucătorii trebuie să dea
      dovadă de un comportament adecvat unei competiții. Astfel, pentru a atinge
      acest obiectiv, fiecare participant trebuie să respecte următoarele
      reguli:
    </p>
    <br />
    <p dir="ltr">
      1. Trebuie să dea dovadă de respect față de organizatori, ceilalți
      participanți și spectatori. De asemenea, să evite cu desăvârșire orice
      comportament neadecvat. În cazul în care un participant va avea un
      comportament nepotrivit, va fi eliminat din competiție.
    </p>
    <br />
    <p dir="ltr">
      2. Trebuie să ajungă la timp la începutul fiecărui meci și să fie
      pregătiți pentru începerea lui. În cazul în care nu sunt prezenți, vor fi
      descalificați.
    </p>
    <br />
    <p dir="ltr">
      3. În cazul în care se observă o abatere de la reguli, aceasta trebuie
      adusă la cunoștință unui organizator.
    </p>
    <br />
    <p dir="ltr">
      4. În cazul în care se observă un avantaj nelegitim într-un meci jucat,
      acesta trebuie adus la cunoștință unui organizator prin canalele de
      comunicare aflate la dispoziție.
    </p>
    <br />
    <p dir="ltr">5. Participanții trebuie să cunoască regulile.</p>
    <br />
    <p dir="ltr">
      (2) Organizatorul respectă și protejează confidențialitatea datelor
      personale din formularul de înscriere. Orice informație personală este
      prelucrată și folosită conform politicii de confidențialitate a datelor
      personale și nu va fi folosită în niciun mod care contravine acestei
      politici.
    </p>
    <ol start="6">
      <li dir="ltr">
        <h1 dir="ltr">Mecanica de competiție</h1>
      </li>
    </ol>
    <h3 dir="ltr">A. Modul de joc</h3>
    <p dir="ltr">Condițiile meciului:</p>
    <p dir="ltr">● Weather: Clear</p>
    <p dir="ltr">● Half Length: 5 minute</p>
    <p dir="ltr">● Difficulty: World Class</p>
    <p dir="ltr">● Injuries: ON</p>
    <p dir="ltr">● Handball: OFF</p>
    <p dir="ltr">
      ● Game Speed: Normal (cei doi jucători pot cădea de acord asupra vitezei)
    </p>
    <p dir="ltr">
      ● Camera: TELE (cei doi jucători pot cădea de acord pentru a schimba
      camera)
    </p>
    <h3 dir="ltr">B. Proceduri pre-joc</h3>
    <p dir="ltr">1. Timpul de pregătire</p>
    <p dir="ltr">
      Jucătorii trebuie să fie prezenți cu 15 minute (în sală), respectiv cu 5
      minute (la console) înainte de începerea jocului. Aceștia ar trebui să
      utilizeze acest timp pentru a se asigura că sunt pregătiți în totalitate.
    </p>
    <p dir="ltr">În acest timp, jucătorul trebuie să:</p>
    <p dir="ltr">● Creeze jocul;</p>
    <p dir="ltr">
      ● Aleagă echipele folosite și să-și alcătuiască echipa de start;
    </p>
    <p dir="ltr">● Testeze echipamentul și să îl configureze;</p>
    <p dir="ltr">● Anunțe când este gata să înceapă.</p>
    <p dir="ltr">2. Defecțiuni tehnice ale echipamentelor</p>
    <p dir="ltr">
      Dacă un jucător se confruntă cu o problemă de natură tehnică cu orice
      echipament asigurat de organizatorii turneului în timpul de pregătire,
      acesta trebuie să informeze imediat arbitrul, astfel încât problema să
      poată fi remediată cât mai rapid.
    </p>
    <p dir="ltr">3. Abandonul</p>
    <p dir="ltr">
      Abandonul din turneu indică organizatorului faptul că un jucător nu mai
      doreşte să participe.
    </p>
    <p dir="ltr">
      Jucătorii pot abandona turneul în orice moment prin anunţarea unui
      organizator.
    </p>
    <p dir="ltr">
      În cazul în care un jucător abandonează, adversarul trece mai departe.
    </p>
    <p dir="ltr">
      Dacă un participant nu se prezintă la ora stabilită, organizatorul
      turneului poate elimina participantul.
    </p>
    <h3 dir="ltr">C. Start-joc</h3>
    <p dir="ltr">
      Odată ce jocul a început, aceasta nu poate fi anulat; excepție în cazul în
      care este autorizat de către un administrator de turneu.
    </p>
    <h3 dir="ltr">D. Proceduri în timpul jocului</h3>
    <p dir="ltr">
      Este interzisă ieșirea voită din joc și utilizarea softurilor suplimentare
      pentru realizarea conexiunii (ex.: hamachi), patch-uri, addon-uri etc.
      După marcarea unui gol, trebuie evitată combinația intenționată de butoane
      ce rezultă într-o celebrare. Jucătorul are voie să pună pauză doar când
      are mingea în posesie. Jucătorul poate fi sancționat sau, în funcție de
      situație, se poate ajunge la excluderea imediată a acestuia.
    </p>
    <h3 dir="ltr">E. Criterii de departajare în grupe</h3>
    <p dir="ltr">
      La stabilirea clasamentului final după desfășurarea tuturor meciurilor din
      grupe, se vor aplica criteriile de mai jos:
    </p>
    <p dir="ltr">1. Numărul total de puncte acumulate;</p>
    <p dir="ltr">
      2. Rezultatele din meciurile directe atunci când sunt două echipe
      implicate în departajare;
    </p>
    <p dir="ltr">
      3. Diferența dintre golurile marcate și cele primite (golaverajul);
    </p>
    <p dir="ltr">4. Numărul de goluri marcate.</p>
    <h3 dir="ltr">F. Desfășurarea fazelor eliminatorii</h3>
    <p dir="ltr">
      În cazul în care un meci este terminat la egalitate se va trece direct la
      loviturile de departajare.
    </p>
    <br />
    <ol start="7">
      <li dir="ltr">
        <h1 dir="ltr">Comportamentul în timpul turneului</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Regulile clare de conduită asigură o experiență plăcută de-a lungul
      turneului. Fiecare jucător este responsabil de atitudinea pozitivă și
      corectă pe care o arată față de ceilalți participanți.
    </p>
    <p dir="ltr">
      (2) Jucătorii ce încalcă regulamentul în timpul turneului vor fi
      sancționați.
    </p>
    <br />
    <ol start="8">
      <li dir="ltr">
        <h1 dir="ltr">Comunicarea dintre jucători</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Jucătorii au responsabilitatea de a urma indicaţiile notate.
      Comunicarea constantă cu oficialii turneului duce la o experienţă mult mai
      transparentă pentru orice persoană inclusă.
    </p>
    <p dir="ltr">
      (2) Jucătorii pot comunica cu ceilalţi participanţi atât timp cât nu
      încalcă vreo regulă menţionată anterior.
    </p>
    <p dir="ltr">
      (3) Pe parcursul unui meci, jucătorii nu au voie să comunice cu jucătorii
      adverși sau cu spectatorii.
    </p>
    <br />
    <ol start="9">
      <li dir="ltr">
        <h1 dir="ltr">Identificarea jucătorului</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Jucătorii sunt încurajaţi să se reprezinte prin: tricouri, embleme,
      bannere etc.
    </p>
    <p dir="ltr">
      (2) Arbitrul turneului are autoritatea finală faţă de orice comportament
      inadecvat al jucătorului. Prin comportament inadecvat se includ:
    </p>
    <p dir="ltr">● Orice referire la droguri sau produse de această natură;</p>
    <p dir="ltr">
      ● Promovarea materialelor legate de orice activitate neautorizată în zona
      turneului;
    </p>
    <p dir="ltr">
      ● Orice semn obscen, profan, vulgar, repulsiv, ofensator sau neplăcut ce
      descrie orice fel de lucru pe care o persoană îl poate considera
      inacceptabil;
    </p>
    <p dir="ltr">● Reclame adresate site-urilor sau produselor pornografice;</p>
    <p dir="ltr">
      ● Cuvinte sau gesturi care ar putea să deranjeze adversarul.
    </p>
    <ol start="10">
      <li dir="ltr">
        <h1 dir="ltr">Dispoziții finale</h1>
      </li>
    </ol>
    <p dir="ltr">
      (1) Participanții au obligativitatea de a respecta prezentul regulament.
      Organizatorii își rezervă dreptul de a aduce modificări prezentului
      regulament, informând participanții în timp util.
    </p>
    <ol start="11">
      <li dir="ltr">
        <h1 dir="ltr">Anexă</h1>
      </li>
    </ol>
    <a
      href="https://docs.google.com/document/d/1gl2l7HGMqaxjleEBF5JeJJad-X4jXkvXD0agwwMNaiA/edit?usp=sharing"
      target="_blank"
      dir="ltr"
    >
      <u>Descarcă declarația pe propria răspundere</u>
    </a>
    <div>
      <br />
    </div>
  </div>
);
