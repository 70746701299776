import React, { useCallback, useState } from "react";
import styles from "./HackathonPage.module.css";
import CompanyCard from "../../../components/card/CompanyCard";
import HackathonSignUp from "../../../components/card/actions/HackathonSignUp";
import useStore from "../../../store/userStore";
import CustomModal from "../../../components/modal/CustomModal";
import Echipe from "../../../components/card/actions/Echipe.jsx";

import { hackathonDescription } from "../../../staticData/general/hackathonDescription";

import { hackathonAdobe } from "../../../staticData/hackathons/adobeHackathon";
import { hackathonEA } from "../../../staticData/hackathons/eaHackathon.js";
import EventStatus from "../../../components/eventStatus/EventStatus";
import CaptainTeam from "../../../components/CaptainTeam/CaptainTeam.jsx";
/*  Userul intra si se face un querry sa se vada daca e in echipa.
        -Daca e in echipa dispare butonul de inscrie-te si se face un 
        querry in functie daca e capitan sau membru
            -Daca e capitan ii arata chenarul de echipa ta cu optiunea de X
            -Daca e membru ii arata doar echipa ta
        -Daca nu e in echipa are buton de inscrie-te iar daca el face inscrierea:
            -Se trimite la baza de date ca e capitan si membri intra in echipe 
*/

const hackathons = [hackathonEA];
const signUpHackathon1Start = new Date("2024-10-22T00:00:00");
const signUpHackathon1End = new Date("2024-10-30T00:00:00");
const showOrder = window.matchMedia("(min-width: 912px)");

function HackathonPage() {
  const user = useStore((state) => state.user);
  const signedUpHackathonId = useStore((state) => state.signedHackathon);
  const teamHackathonId = useStore((state) => state.teamHackathonId);
  const teamId = useStore((state) => state.teamHackathonId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div className={styles.hackathonContainter}>
      <CustomModal
        onClose={closeModal}
        isOpen={isModalOpen}
        title="Înscriere Hackatoane"
      >
        <div>
          <p className={styles.modalParagraph}>
            Dacă ești căpitan de echipă, află ID-urile de înscriere ale
            membrilor
          </p>
          <p className={styles.modalParagraph}>
            Dacă ești membru de echipă, trimite căpitanului ID-ul pentru ca
            acesta să înscrie echipa la hackathon
          </p>

        </div>
      </CustomModal>
      <h1 className={styles.title}>Hackathon</h1>
      <div className={styles.teamContainer}>
        <div className={(teamHackathonId === 0) ? styles.eventsDescriereNoTeam : styles.eventsDescriere}>{hackathonDescription}</div>

        {showOrder.matches && <Echipe display={true} />}
      </div>

      {new Date() >= signUpHackathon1Start &&
        new Date() <= signUpHackathon1End && (
          <h2 className={styles.hackathonsDate}>
            <span className={styles.dateText}>2 noiembrie</span>
          </h2>
        )}

      <div className={styles.cardsContainer}>
        <EventStatus
          eventTitle=""
          startDate={signUpHackathon1Start}
          endDate={signUpHackathon1End}
          messageStart="vor începe în curând"
          messageEnd="Înscrierile la hackathon s-au încheiat"
        >
          {hackathons.map((hackathon) => {
            return (
              <div className={styles.hackathonCard}>
                <CompanyCard key={hackathon.id} eventData={hackathon}> </CompanyCard>
                <HackathonSignUp eventID={hackathon.id} title={hackathon.details.title}
                />
              </div>
            );
          })}
        </EventStatus>
      </div>
      {/* <div className={styles.teamContent}>
        {(teamHackathonId !== 0) && <CaptainTeam display={teamHackathonId !== 0} userId={user.id} paramEventId={signedUpHackathonId} paramTeamId={teamId} teamSize={4} />}
      </div> */}
      <h1 className={styles.hackathonID}>
        Id-ul tău de hackathon este:
        <span className={styles.userID}>{user.id}</span>
        <img
          onClick={openModal}
          src="/icons/info-icon.svg"
          alt="info"
          className={styles.infoIcon}
        />
      </h1>
      {!(showOrder.matches) && <Echipe display={true} />}
    </div>
  );
}

export default HackathonPage;
