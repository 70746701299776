export const lanPartyDescription = (
  <>
    <p>
      <span>
        &emsp; Ești pasionat de jocuri video? Atunci nu trebuie să ratezi
        LAN Party-ul nostru! Reunește-te cu alți gameri pentru o zi plină de
        adrenalină și competiție. Indiferent dacă ești un jucător competitiv sau
        doar &icirc;ți place să te distrezi, vei găsi aici un mediu prietenos și
        incitant unde &icirc;ți poți demonstra abilitățile de gaming.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        &emsp; &Icirc;n cadrul proiectului ITFest, vă invităm să
        participați și la competiția de gaming &icirc;n cadrul evenimentului LAN
        Party cu premii atractive. De la League of Legends și Counter Strike 2,
        p&acirc;nă la FIFA, evenimentul vă oferă jocurile voastre favorite
        pentru care să vă adunați o echipă și să obțineți premiul mult dorit!
      </span>
    </p>
    <br></br>
    <p>
      Locație:&nbsp;
      <a target="_blank" href="https://maps.app.goo.gl/AHCgsbN7dPGhh6mM7"><u>Discovery Arena</u></a>
    </p>
    <p>
      Perioadă înscrieri: 1-7 noiembrie
    </p>
    <p>
      Perioadă calificări: 9-10 noiembrie
    </p>
    <p>
      Discord căutare echipe: 
      <a target="_blank" href="https://discord.gg/7YJTzAjCrH"><u>LAN Party ITFest</u></a>
    </p>
    <p>&nbsp;</p>
  </>
);
