export const GDPRText = (
  <div class="c10 c16 doc-content">
    <p dir="ltr">
    Acord privind prelucrarea datelor cu caracter personal
</p>
<p dir="ltr">
    Deoarece asigurarea protecției dvs. în ceea ce privește prelucrare datelor
    cu caracter personal, reprezintă pentru noi un obiectiv extrem de important,
    am depus toate diligentele necesare pentru a ne conforma standardelor impuse
    prin Regulamentul UE 2016/679
    (<a href="https://eur-lex.europa.eu/legal-content/ro/TXT/PDF/?uri=CELEX:32016R0679">https://eur-lex.europa.eu/legal-content/ro/TXT/PDF/?uri=CELEX:32016R0679</a>) 
    și prin orice alt act normativ în vigoare pe teritoriul României.
</p>
<p dir="ltr">
    Un pas important pentru realizarea acestui obiectiv este reprezentat de
    informarea în legătură cu modul în care datele dumneavoastră vor fi
    prelucrate (prelucrare înseamnă orice operațiune sau set de operațiuni
    efectuate asupra datelor cu caracter personal sau asupra seturilor de date
    cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum
    ar fi colectarea, înregistrarea, organizarea, structurarea, stocarea,
    adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea
    prin transmitere, diseminarea sau punerea la dispoziție în orice alt mod,
    alinierea sau combinarea, restricționarea, ștergerea sau distrugerea).
</p>
<p dir="ltr">
    Ne rezervăm dreptul de a modifica și actualiza Politica de Confidențialitate
    și Politica de Cookie.
</p>
<h3 dir="ltr">
    1. Cine suntem și modul în care putem fi contactați:
</h3>
<br/>
<p dir="ltr">
    SiSC este o organizație studențească, neguvernamentală, autonomă,
    independentă și nepartinică înființată în baza Legii nr. 21/1924 și a Legii
    54/1991.
</p>
<br/>
<p dir="ltr">
    SiSC se constituie ca principalul partener studențesc și neguvernamental în
    raport cu structurile Facultății de Cibernetică, Statistică și Informatică
    Economică din cadrul Academiei de Studii Economice din București.
</p>
<br/>
<p dir="ltr">
    SiSC se constituie prin asocierea liberă a membrilor săi ca persoană
    juridică de drept privat fără scop patrimonial, în conformitate cu
    prevederile Legii 62/2011 și cu OG nr. 26/2000, completată prin OG nr.
    37/2003.
</p>
<br/>
<p dir="ltr">
    SiSC se evidențiază printr-un reprezentant legal: Anca-Ioana Drumeși.
</p>
<br/>
<p dir="ltr">
    Adresă: Str. Frumoasă, nr. 31, sala 11
</p>
<br/>
<p dir="ltr">
    Nr. de telefon: 0744124402
</p>
<br/>
<p dir="ltr">
    E-mail: office@sisc.ro
</p>
<br/>
<p dir="ltr">
    Pagina Facebook:
    <a href="https://www.facebook.com/sisc.ase/">
        https://www.facebook.com/sisc.ase/
    </a>
</p>
<br/>
<br/>
<br/>
<h3 dir="ltr">
    2. Colectare date/ tipuri de date
</h3>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Colectate automat în urma accesării (de tipul)
        </p>
    </li>
    <ul>
        <li dir="ltr">
            <p dir="ltr">
                Adresă IP;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Data și ora accesului;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Conținutul cererii (website specific);
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Starea accesului/codul de stare HTTP;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Volumul de date transferat;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Acces solicitat la website;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Browser, setări de limbă, versiunea de browser, sistem de
                operare și suprafață.
            </p>
        </li>
    </ul>
    <li dir="ltr">
        <p dir="ltr">
            Colectate în urma completării formularului de contact:
        </p>
    </li>
    <ul>
        <li dir="ltr">
            <p dir="ltr">
                Nume și Prenume;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Adresă de email;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Număr de telefon;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Unitatea de învățământ;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Anul de studiu;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                CV.
            </p>
        </li>
    </ul>
</ol>
<h3 dir="ltr">
    3. Fișierele cookie
</h3>
<h4 dir="ltr">
    Acest Website nu folosește așa-numitele „fișiere cookie”.
</h4>
<h3 dir="ltr">
    4. Scopul prelucrării datelor în vederea realizării proiectului ITFest
    ediția 2024.
</h3>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            În vederea realizării unei comunicări cu dumneavoastră
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            În cazul în care inițiați o comunicare cu noi, datele dumneavoastră
            vor fi stocate pentru perioada necesară realizării scopului în care
            ne-ați contactat sau până la momentul în care ne solicitați
            ștergerea datelor, oricare dintre aceste momente ar surveni primul;
        </p>
    </li>
</ol>
<ol start="2">
    <li dir="ltr">
        <p dir="ltr">
            Pentru a vă putea oferi servicii special configurate nevoilor
            dumneavoastră
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            În scopuri de marketing
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Putem utiliza informaţiile respective pentru îmbunătăţirea
            produselor şi serviciilor pe care vi le oferim;
        </p>
    </li>
</ol>
<ol start="4">
    <li dir="ltr">
        <p dir="ltr">
            Pentru apărarea intereselor noastre legitime
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Pentru apărarea intereselor noastre legitime (În cazul unor situații
            excepționale precum atacuri cibernetice care pot periclita siguranța
            platformei noastre sau în cazul în care informațiile sunt solicitate
            de către instituțiile publice competente în vederea soluționării
            anchetelor în desfășurare, ne rezervăm dreptul de prelucra
            informațiile vizate).
        </p>
    </li>
</ol>
<br/>
<h3 dir="ltr">
    5. Cât timp păstrăm datele dumneavoastră cu caracter personal
</h3>
<p dir="ltr">
    Durata de timp pentru care vor fi păstrate datele dumneavoastră este
    limitată și va fi determinate de perioada necesară îndeplinirii scopurilor
    în vederea cărora sunt prelucrate datele, această perioadă neputând să
    depășească 2 ani.
</p>
<h3 dir="ltr">
    6. Terți
</h3>
<p dir="ltr">
    Adobe, Stripe, Veridion, Medicover, Dell, Devmind, EARomania
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Scop: Trimiterea de materiale promoționale, accesul dumneavoastră la
            oferte de muncă și informații de interes.
        </p>
    </li>
</ol>
<p dir="ltr">
    Mențiune
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            În cazul în care ne revine obligația legală sau dacă este necesar
            pentru a ne apăra un interes legitim, putem de asemenea divulga
            anumite date cu caracter personal unor autorități publice.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Ne asigurăm că accesul la datele dvs. de către terții persoane
            juridice de drept privat se realizeaza în conformitate cu
            prevederile legale privind protecția datelor și confidențialitatea
            informațiilor, în baza unor contracte încheiate cu aceștia.
        </p>
    </li>
</ol>
<h3 dir="ltr">
    7. Teritorialitate:
</h3>
<p dir="ltr">
    Prelucrarea datelor se face doar pe teritoriul României.
</p>
<h3 dir="ltr">
    8. Drepturile Dvs
</h3>
<h4 dir="ltr">
    Dreptul de acces al persoanei vizate
</h4>
<p dir="ltr">
    (1)   Persoana vizată are dreptul de a obține din partea operatorului o
    confirmare că se prelucrează sau nu date cu caracter personal care o privesc
    și, în caz afirmativ, acces la datele respective și la următoarele
    informații:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            scopurile prelucrării;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            categoriile de date cu caracter personal vizate;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            destinatarii sau categoriile de destinatari cărora datele cu
            caracter personal le-au fost sau urmează să le fie divulgate, în
            special destinatari din țări terțe sau organizații internaționale;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            acolo unde este posibil, perioada pentru care se preconizează că vor
            fi stocate datele cu caracter personal sau, dacă acest lucru nu este
            posibil, criteriile utilizate pentru a stabili această perioadă;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            existența dreptului de a solicita operatorului rectificarea sau
            ștergerea datelor cu caracter personal ori restricționarea
            prelucrării datelor cu caracter personal referitoare la persoana
            vizată sau a dreptului de a se opune prelucrării;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            dreptul de a depune o plângere în fața unei autorități de
            supraveghere;
        </p>
    </li>
</ol>
<h4 dir="ltr">
    Dreptul la rectificare
</h4>
<p dir="ltr">
    (1)   Persoana vizată are dreptul de a obține de la operator, fără
    întârzieri nejustificate, rectificarea datelor cu caracter personal inexacte
    care o privesc. Ținându-se seama de scopurile în care au fost prelucrate
    datele, persoana vizată are dreptul de a obține completarea datelor cu
    caracter personal care sunt incomplete, inclusiv prin furnizarea unei
    declarații suplimentare.
</p>
<h4 dir="ltr">
    Dreptul la ștergerea datelor („dreptul de a fi uitat”)
</h4>
<p dir="ltr">
    (1)   Persoana vizată are dreptul de a obține din partea operatorului
    ștergerea datelor cu caracter personal care o privesc, fără întârzieri
    nejustificate, iar operatorul are obligația de a șterge datele cu caracter
    personal fără întârzieri nejustificate în cazul în care se aplică unul
    dintre următoarele motive:
</p>
<p dir="ltr">
    (a) datele cu caracter personal nu mai sunt necesare pentru îndeplinirea
    scopurilor pentru care au fost colectate sau prelucrate;
</p>
<p dir="ltr">
    (b) persoana vizată își retrage consimțământul pe baza căruia are loc
    prelucrarea, în conformitate cu articolul 6 alineatul (1) litera (a) sau cu
    articolul 9 alineatul (2) litera (a), și nu există niciun alt temei juridic
    pentru prelucrarea;
</p>
<p dir="ltr">
    (c) persoana vizată se opune prelucrării în temeiul articolului 21 alineatul
    (1) și nu există motive legitime care să prevaleze în ceea ce privește
    prelucrarea sau persoana vizată se opune prelucrării în temeiul articolului
    21 alineatul (2);
</p>
<p dir="ltr">
    (d) datele cu caracter personal au fost prelucrate ilegal;
</p>
<p dir="ltr">
    (e) datele cu caracter personal trebuie șterse pentru respectarea unei
    obligații legale care revine operatorului în temeiul dreptului Uniunii sau
    al dreptului intern sub incidența căruia se află operatorul;
</p>
<p dir="ltr">
    (f) datele cu caracter personal au fost colectate în legătură cu oferirea de
    servicii ale societății informaționale menționate la articolul 8 alineatul
    (1).
</p>
<p dir="ltr">
    (2)   În cazul în care operatorul a făcut publice datele cu caracter
    personal și este obligat, în temeiul alineatului (1), să le șteargă,
    operatorul, ținând seama de tehnologia disponibilă și de costul
    implementării, ia măsuri rezonabile, inclusiv măsuri tehnice, pentru a
    informa operatorii care prelucrează datele cu caracter personal că persoana
    vizată a solicitat ștergerea de către acești operatori a oricăror linkuri
    către datele respective sau a oricăror copii sau reproduceri ale acestor
    date cu caracter personal.
</p>
<p dir="ltr">
    (3)   Alineatele (1) și (2) nu se aplică în măsura în care prelucrarea este
    necesară:
</p>
<p dir="ltr">
    (a) pentru exercitarea dreptului la liberă exprimare și la informare;
</p>
<p dir="ltr">
    (b) pentru respectarea unei obligații legale care prevede prelucrarea în
    temeiul dreptului Uniunii sau al dreptului intern care se aplică
    operatorului sau pentru îndeplinirea unei sarcini executate în interes
    public sau în cadrul exercitării unei autorități oficiale cu care este
    învestit operatorul;
</p>
<p dir="ltr">
    (c) din motive de interes public în domeniul sănătății publice, în
    conformitate cu articolul 9 alineatul (2) literele (h) și (i) și cu
    articolul 9 alineatul (3);
</p>
<p dir="ltr">
    (d) în scopuri de arhivare în interes public, în scopuri de cercetare
    științifică sau istorică ori în scopuri statistice, în conformitate cu
    articolul 89 alineatul (1), în măsura în care dreptul menționat la alineatul
    (1) este susceptibil să facă imposibilă sau să afecteze în mod grav
    realizarea obiectivelor prelucrării respective;
</p>
<p dir="ltr">
    (e) pentru constatarea, exercitarea sau apărarea unui drept în instanță.
</p>
<h4 dir="ltr">
    Dreptul la restricționarea prelucrării
</h4>
<p dir="ltr">
    (1)   Persoana vizată are dreptul de a obține din partea operatorului
    restricționarea prelucrării în cazul în care se aplică unul din următoarele
    cazuri:
</p>
<p dir="ltr">
    (a) persoana vizată contestă exactitatea datelor, pentru o perioadă care îi
    permite operatorului să verifice exactitatea datelor;
</p>
<p dir="ltr">
    (b) prelucrarea este ilegală, iar persoana vizată se opune ștergerii datelor
    cu caracter personal, solicitând în schimb restricționarea utilizării lor;
</p>
<p dir="ltr">
    (c) operatorul nu mai are nevoie de datele cu caracter personal în scopul
    prelucrării, dar persoana vizată i le solicită pentru constatarea,
    exercitarea sau apărarea unui drept în instanță;
</p>
<p dir="ltr">
    (d) persoana vizată s-a opus prelucrării în conformitate cu articolul 21
    alineatul (1), pentru intervalul de timp în care se verifică dacă drepturile
    legitime ale operatorului prevalează asupra celor ale persoanei vizate.
</p>
<p dir="ltr">
    (2)   În cazul în care prelucrarea a fost restricționată în temeiul
    alineatului (1), astfel de date cu caracter personal pot, cu excepția
    stocării, să fie prelucrate numai cu consimțământul persoanei vizate sau
    pentru constatarea, exercitarea sau apărarea unui drept în instanță sau
    pentru protecția drepturilor unei alte persoane fizice sau juridice sau din
    motive de interes public important al Uniunii sau al unui stat membru.
</p>
<p dir="ltr">
    (3)   O persoană vizată care a obținut restricționarea prelucrării în
    temeiul alineatului (1) este informată de către operator înainte de
    ridicarea restricției de prelucrare.
</p>
<h4 dir="ltr">
    Dreptul la portabilitatea datelor
</h4>
<p dir="ltr">
    (1)   Persoana vizată are dreptul de a primi datele cu caracter personal
    care o privesc și pe care le-a furnizat operatorului într-un format
    structurat, utilizat în mod curent și care poate fi citit automat și are
    dreptul de a transmite aceste date altui operator, fără obstacole din partea
    operatorului căruia i-au fost furnizate datele cu caracter personal, în
    cazul în care:
</p>
<p dir="ltr">
    (a) prelucrarea se bazează pe consimțământ în temeiul articolului 6
    alineatul (1) litera (a) sau al articolului 9 alineatul (2) litera (a) sau
    pe un contract în temeiul articolului 6 alineatul (1) litera (b);
</p>
<p dir="ltr">
    (b) prelucrarea este efectuată prin mijloace automate.
</p>
<p dir="ltr">
    (2)   În exercitarea dreptului său la portabilitatea datelor în temeiul
    alineatului (1), persoana vizată are dreptul ca datele cu caracter personal
    să fie transmise direct de la un operator la altul acolo unde acest lucru
    este fezabil din punct de vedere tehnic.
</p>
<p dir="ltr">
    (3)   Exercitarea dreptului menționat la alineatul (1) din prezentul articol
    nu aduce atingere articolului 17. Respectivul drept nu se aplică prelucrării
    necesare pentru îndeplinirea unei sarcini executate în interes public sau în
    cadrul exercitării unei autorități oficiale cu care este învestit
    operatorul.
</p>
<p dir="ltr">
    (4)   Dreptul menționat la alineatul (1) nu aduce atingere drepturilor și
    libertăților altora.
</p>
<h4 dir="ltr">
    Dreptul de a fi notificat în legătură cu rectificarea, ștergerea sau
    restricționarea prelucrării datelor cu caracter personal
</h4>
<p dir="ltr">
    (1)   Operatorul comunică fiecărui destinatar căruia i-au fost divulgate
    datele cu caracter personal orice rectificare sau ștergere a datelor cu
    caracter personal sau restricționare a prelucrării efectuate în conformitate
    cu articolul 16, articolul 17 alineatul (1) și articolul 18, cu excepția
    cazului în care acest lucru se dovedește imposibil sau presupune eforturi
    disproporționate. Operatorul informează persoana vizată cu privire la
    destinatarii respectivi dacă persoana vizată solicită acest lucru.
</p>
<p dir="ltr">
    9. Date colectate în cadrul evenimentului
</p>
<p dir="ltr">
    Prin această notă dorim să vă informăm cu privire la:
</p>
<p dir="ltr">
    (i) scopurile pentru care colectăm și prelucrăm datele dvs. cu caracter
    personal;
</p>
<p dir="ltr">
    (ii) temeiul legal al prelucrării acestor date;
</p>
<p dir="ltr">
    (iii) categoriile de date pe care le colectăm de la dvs. și pe care le
    prelucrăm;
</p>
<p dir="ltr">
    (iv) durata prelucrării acestor date;
</p>
<p dir="ltr">
    (v) drepturile de care beneficiați în calitate de persoană vizată și
    modalitatea în care vi le poți exercita;
</p>
<p dir="ltr">
    (vi) cui dezvăluim datele dvs. cu caracter personal.
</p>
<p dir="ltr">
    Scopul pentru care sunt colectate și prelucrate datele
</p>
<p dir="ltr">
    Datele personale care ne sunt transmise prin intermediul CV-urilor și/sau
    care sunt disponibile pe platformele de specialitate (de exemplu, LinkedIn)
    sunt prelucrate în vederea participării  la evenimentele din cadrul ITFest
    2024.
</p>
<p dir="ltr">
    Categoriile de date colectate și prelucrate
</p>
<p dir="ltr">
    Datele cu caracter personal pe care le prelucrăm sunt cele pe care ni le
    puneți la dispoziție în cadrul CV-ului și/sau care sunt disponibile pe
    platformele de specialitate și includ, printre altele: numele și prenumele,
    fotografia, adresa de domiciliu și/sau adresa de reședință, adresa de e-mail
    personală, numărul de telefon personal, data nașterii, sexul, starea civilă,
    naționalitatea, experiența profesională, studii, abilități și cunoștințe,
    precum și orice alte date cu caracter personal incluse în CV-ul pe care îl
    transmiteți.
</p>
<p dir="ltr">
    În măsura în care documentele pe care ni le transmiteți conțin și datele
    personale ale altor persoane fizice (de exemplu, persoane de contact pentru
    verificarea referințelor), vă rugăm să informați respectivele persoane
    anterior transmiterii către SiSC a datelor lor personale cu privire la
    modalitatea în care datelor lor cu caracter personal vor fi prelucrate de
    către societate.
</p>
<p dir="ltr">
    Durata prelucrării acestor date
</p>
<p dir="ltr">
    Datele cu caracter personal pe care ni le puneți la dispoziție în cadrul
    CV-ului și/sau care sunt disponibile pe platformele de specialitate vor fi
    stocate și prelucrate pe durata desfășurării proiectului ITFest 2024, urmând
    ca la finalul proiectului acestea să fie șterse.
</p>
<p dir="ltr">
    Securitatea prelucrării
</p>
<p dir="ltr">
    SiSC va asigura securitatea prelucrării datelor dvs. începând cu momentul
    conceperii acestora prin implementarea unor măsuri tehnice și organizatorice
    adecvate.
</p>
<p dir="ltr">
    De asemenea, SiSC vă va informa, fără întârzieri nejustificate, în cazul în
    care o eventuală încălcare a securității datelor este susceptibilă să
    genereze un risc ridicat pentru drepturile și libertățile dvs., cu excepția
    cazurilor indicate în art. 34 din Regulament.
</p>
<p dir="ltr">
    Drepturile de care beneficiați în calitate de persoană vizată
</p>
<p dir="ltr">
    În considerarea calității dvs. de persoană vizată, beneficiați de
    următoarele drepturi:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul de a fi informat cu privire la toate aspectele esențiale ale
            prelucrării de date;
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul de acces la datele prelucrate și la o serie de informații,
            conform prevederilor legale, drept care vă permite să fiți informat
            dacă datele dvs. personale sunt prelucrate de noi , precum și cu
            privire la detaliile disponibile în acest sens (inclusiv dar fără a
            se limita la scopurile prelucrării, categoriile de date cu caracter
            personal vizate, destinatarii sau categoriile de destinatari cărora
            datele cu caracter personal le-au fost sau urmează să le fie
            divulgate etc.),
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul de a obține rectificarea și/sau completarea datelor
            personale, fără întârzieri nejustificate;
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul de a obține ștergerea datelor personale, fără întârzieri
            nejustificate, în cazurile prevăzute de lege (de exemplu, atunci
            când datele cu caracter personal nu mai sunt necesare pentru
            îndeplinirea scopurilor pentru care au fost colectate sau
            prelucrate);
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul de a obține restricționarea prelucrării în cazurile
            prevăzute de lege (de exemplu, atunci când contestați exactitatea
            datelor, pentru o perioadă care permite operatorului să verifice
            exactitatea datelor).
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul la portabilitatea datelor Aveți dreptul de a obține date
            personale prelucrate într-un mod structurat, utilizat în mod curent
            și care poate fi citit automat în scopul de a transmite aceste date
            altui operator, fără obstacole din partea operatorului, în cazurile
            prevăzute de art. 20 din Regulament.
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul la opoziție Aveți dreptul de a vă opune, din motive legate
            de situația dvs. particulară, prelucrării în temeiul interesului
            public sau al interesului legitim al operatorului sau în scopuri de
            marketing direct, inclusiv creării de profiluri pe baza
            respectivelor justificări, cu excepția prevăzute de art. 21 din
            Regulament.
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul de a nu face obiectul unei decizii bazate exclusiv pe
            prelucrarea automată, în afara excepțiilor prevăzute de art. 22 din
            Regulament.
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul de a depune o plângere în fața Autorităţii Naţionale de
            Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP).
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Dreptul la formula o  cale de atac, în fața instanțelor de judecată
            din România.
        </p>
    </li>
</ul>
<p dir="ltr">
    10. Dispoziții finale
</p>
<p dir="ltr">
    Fără a vă afecta dreptul dumneavoastră de a contacta în orice moment
    autoritatea de supraveghere, puteți să ne contactați în prealabil la
    adresa de email <a href="mailto:office@sisc.ro">office@sisc.ro</a> și vom
    depune toate eforturile necesare pentru a rezolva orice problemă.
</p>
<p dir="ltr">
    Aveți dreptul să depuneți o plângere la autoritatea de supraveghere cu
    privire la prelucrarea datelor dvs. cu caracter personal. În România,
    datele de contact ale autorității de supraveghere pentru protecția
    datelor sunt următoarele:
</p>
<p dir="ltr">
    Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter
    Personal
</p>
<p dir="ltr">
    B-dul G-ral. Gheorghe Magheru nr. 28-30, Sector 1, cod poștal 010336,
    București, Romania
</p>
<p dir="ltr">
    Telefon: +40.318.059.211 sau +40.318.059.212;
</p>
<p dir="ltr">
    E-mail:anspdcp@dataprotection.ro
</p>
<br/>
  </div>
);
