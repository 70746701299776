import React from 'react'
import styles from "./CompanyCard.module.css"
import Details from './Details'
import Rules from './Rules'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'

function CompanyCard({ children, eventData, movable }) {
  const { rules, companyLogo } = eventData
  return (
    <div className={styles.cardContainer}>
      <div className={styles.imgContainer}>
        <img src={companyLogo} className={styles.cardImg} alt="company logo" />
        {movable &&<FontAwesomeIcon className={styles.bars} icon={faGripLines} size='2xl' style={{ color: "#000000" }} />}
      </div>
      <div className={styles.detailsAndRulesContainer}>
        {console.log(eventData)}
        {"description" in eventData.details ? <Details details={eventData.details}/> : null}
        {eventData?.rules && <Rules rules={rules} />}
      </div>

      {children}
    </div>
  )
}

export default CompanyCard