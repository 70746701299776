import { workshopRules } from "../../pages/user-pages/workshop/workshopRules";

export const workshopCompanyData = {
  companyLogo: "/icons/adobe-icon.png",
  details: {
    title: "Workshop Adobe", // nume companie
    // descriere companie
    description: <div>
      <p>Alătură-te pentru a descoperi cum e să fii un intern al companiei,
     cum să compui cel mai top CV, dar și cum să te pregătești pentru un interviu.
     </p>
     <p>
      
     </p>
     <a href="https://us06web.zoom.us/j/84045680023?pwd=9RSS7aR7HrQBSarFx4gmVmjJgS12Q2.1" target="_blank"><u>Link Meeting Zoom</u></a>
      </div>
  },
  rules: {
    title: "Regulament Workshop",
    text: workshopRules,
  },
};
