import React, { useEffect, useState } from "react";
import styles from "./HackathonTeam.module.css";
import { APIURL } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function HackathonTeam({ teamID, captainId }) {
  const [hackathonTeam, setHackathonTeam] = useState(teamID);
  const [showMembers, setShowMembers] = useState(false);
  const [captain, setCaptain] = useState();

  useEffect(() => {
    axios
      .get(`${APIURL}/user/getUserByID/${captainId}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res.data);
        setCaptain(res.data);
      });
  }, [captainId]);

  return (
    <div className={styles.hackathonTeamContainer}>
      <div className={styles.hackathonTeamPill}>
        <h3>Echipa {hackathonTeam.name}</h3>
        <FontAwesomeIcon
          className={styles.plusLanParty}
          icon={faPlus}
          onClick={() => setShowMembers(!showMembers)}
        />
      </div>

      <div className={styles.hackathonTeamMemberList}>
        {showMembers &&
          hackathonTeam.members.map((member) => {
            if (member.id === hackathonTeam.captainId)
              return (
                <div className={styles.hackathonTeamCaptain}>
                  <p>Căpitan: {member.name}</p>
                  <p>{captain.email}</p>
                  <p>{captain.phoneNumber}</p>
                </div>
              );
            else
              return <p key={member.id}>{`ID ${member.id}: ${member.name}`}</p>;
          })}
      </div>
    </div>
  );
}

export default HackathonTeam;
